import "./style.css";
import React from 'react';
import { useTranslation } from 'react-i18next';

const App = () => {
    const { t, i18n, locale } = useTranslation();
    const selectedLang = i18n.language;

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

  return (
      <>
          <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light site-navbar-target" id="ftco-navbar">
              <div className="container">
                  <a className="navbar-brand" href="/">
                      <span>{t('name')}</span>
                  </a>
                  <button className="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="oi oi-menu"></span> <span className="">{t('menu')}</span>
                  </button>
                  <div className="collapse navbar-collapse" id="ftco-nav">
                      <ul className="navbar-nav nav ml-auto text-center">
                          <li className="nav-item"><a href="#about-section" className="nav-link"><span className="">{t('about1')}</span></a></li>
                          <li className="nav-item"><a href="#resume-section" className="nav-link"><span className="">{t('resume')}</span></a></li>
                          <li className="nav-item"><a href="#skills-section" className="nav-link"><span className="">{t('skills1')}</span></a></li>
                          <li className="nav-item"><a href="#contact-section" className="nav-link"><span className="">{t('contact1')}</span></a></li>
                      </ul>
                      <ul className="navbar-nav nav ml-auto text-center languages">
                          <li className="nav-item">
                              <a
                                  onClick={() => changeLanguage('en')}
                                  className={`nav-link ${selectedLang === "en" ? "selected" : ""}`}
                              >
                                  <img src="./imgs/en.svg" height="20" title={t('english')} />
                              </a>
                          </li>
                          <li className="nav-item">
                              <a
                                  onClick={() => changeLanguage('no')}
                                  className={`nav-link ${selectedLang === "no" ? "selected" : ""}`}
                              >
                                  <img src="./imgs/no.svg" height="22" title={t('norwegian')} />
                              </a>
                          </li>
                          <li className="nav-item">
                              <a
                                  onClick={() => changeLanguage('hu')}
                                  className={`nav-link ${selectedLang === "hu" ? "selected" : ""}`}
                              >
                                  <img src="./imgs/hu.svg" height="22" title={t('hungarian')} />
                              </a>
                          </li>
                      </ul>
                  </div>
              </div>
          </nav>
          <section className="ftco-about img ftco-section ftco-no-pb mt-0 mt-md-5 mb-0 mb-md-3 mb-lg-1" id="about-section">
              <div className="container pb-0 pb-lg-1">
                  <div className="row d-flex justify-content-center">
                      <div className="col-8 col-md-6 col-lg-5 d-flex">
                          <div className="img-about img d-flex align-items-stretch">
                              <div className="overlay"></div>
                              <div className="img d-flex align-self-stretch align-items-center me-picture">
                              </div>
                          </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-7 pl-lg-5 pb-0 pb-lg-5 mt-4 mt-l-0">
                          <div className="row justify-content-start pb-3">
                              <div className="col-md-12 heading-section mt-0 mt-md-4">
                                  <ul className="mt-4 about-info px-md-0 px-2">
                                      <li className="d-flex"><span>{t('name1')}:</span> <span>{t('name')}</span></li>
                                      <li className="d-flex"><span>{t('birthdate')}:</span> <span>{t('dob')}</span></li>
                                      <li className="d-flex"><span>{t('address1')}:</span> <span>{t('address')}</span></li>
                                      <li className="d-flex"><span>{t('email')}</span> <span>hello@mateszabo.dev</span></li>
                                      <li className="d-flex"><span>{t('phone')}: </span> <span>+36205136009</span></li>
                                      <li className="d-flex"><span>{t('languages1')}: </span>
                                          <span>
                                            <p className="mb-0">{t('english1')}</p>
                                              <p className="mb-0">{t('norwegian1')}</p>
                                              <p className="mb-0">{t('hungarian1')}</p>
                                          </span>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <div className="counter-wrap d-flex mt-md-3">
                              <div className="text">
                                  <p className="mb-4">
                                      <span className="number" data-number="12">12</span>
                                      <span>{t('experience')}</span>
                                  </p>
                                  <p><a target="_blank" href={selectedLang === "no" ? "mate_szabo_cv_no.pdf" : "mate_szabo_cv.pdf"} className="btn btn-primary py-4 px-5">{t('downloadcv')}</a></p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="ftco-section" id="services-section">
              <div className="container">
                  <div className="row">
                      <div className="col-md-4 text-center d-flex">
                          <div href="#" className="services-1">
							<span className="icon">
								<i className="flaticon-analysis"></i>
							</span>
                              <div className="desc">
                                  <h3 className="mb-5">{t('roles1')}</h3>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-4 text-center d-flex">
                          <div href="#" className="services-1">
							<span className="icon">
								<i className="flaticon-flasks"></i>
							</span>
                              <div className="desc">
                                  <h3 className="mb-5">{t('roles2')}</h3>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-4 text-center d-flex">
                          <div href="#" className="services-1">
							<span className="icon">
								<i className="flaticon-ideas"></i>
							</span>
                              <div className="desc">
                                  <h3 className="mb-5">{t('roles3')}</h3>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="ftco-section ftco-no-pb" id="resume-section">
              <div className="container">
                  <div className="row justify-content-center pb-5">
                      <div className="col-md-10 heading-section text-center mb-4">
                          <h1 className="big big-2">{t('resume')}</h1>
                          <h2 className="mb-4">{t('resume')}</h2>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-md-6">
                          <div className="resume-wrap">
                              <span className="date">2022-</span>
                              <h2>{t('resume1_title')}</h2>
                              <span className="position">{t('resume1_institution')}</span>
                              <p className="mt-4">{t('resume1_description')}: <a target="_blank" href="https://www.ford.com/custom-order">Ford</a>, <a target="_blank" href="https://www.cognizant.com/">Cognizant</a> {t('and')} <a target="_blank" href="https://ds.pl/">Dynamic Solutions</a>.
                                  {" "}{t('resume1_description2')}
                                  <br />{t('resume1_description3')}
                                  <br />{t('resume1_description4')}
                              </p>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="resume-wrap">
                              <span className="date">2021-2022</span>
                              <h2>{t('resume2_title')}</h2>
                              <span className="position">{t('resume2_institution')}</span>
                              <div className="mt-4">
                                  <dl>
                                      <dt><strong>{t('resume2_description')}</strong></dt> <dd>{t('resume2_description3')} <a target="_blank" href="https://icftechnology.com">ICF Tech</a></dd>
                                      <dt><strong>{t('resume2_description2')}</strong></dt> <dd> {t('resume2_description4')}</dd>
                                  </dl>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="resume-wrap">
                              <span className="date">2020-2021</span>
                              <h2>{t('resume3_title')}</h2>
                              <span className="position">{t('resume3_institution')}</span>
                              <p className="mt-4">
                                  {t('resume3_description')} <a target="_blank" href="https://telekom.de">Telekom {t('website')}</a>
                              </p>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="resume-wrap">
                              <span className="date">2020-2020</span>
                              <h2>{t('resume4_title')}</h2>
                              <span className="position">{t('resume4_institution')}</span>
                              <p className="mt-4">
                                  <a target="_blank" href="https://www.wqpredictive.com" rel="noopener noreferrer">WorldQuant Predictive</a> {t('resume4_description')}
                              </p>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="resume-wrap">
                              <span className="date">2015-2020</span>
                              <h2>{t('resume5_title')}</h2>
                              <span className="position">{t('resume5_institution')}</span>
                              <p className="mt-4">
                                  <a target="_blank" href="https://www.doclerholding.com" rel="noopener noreferrer">Docler Holding</a> {t('resume5_description')}
                              </p>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="resume-wrap">
                              <span className="date">2012-2013</span>
                              <h2>{t('resume6_title')}</h2>
                              <span className="position">{t('resume6_institution')}</span>
                              <p className="mt-4">
                                  <a target="_blank" href="https://leanrocket.se" rel="noopener noreferrer">LeanRocket</a> {t('resume6_description')}
                              </p>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="resume-wrap">
                              <span className="date">2011-2015</span>
                              <h2>{t('resume7_title')}</h2>
                              <span className="position">{t('resume7_institution')}</span>
                              <p className="mt-4">
                                  {t('resume7_description')}
                              </p>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="resume-wrap">
                              <span className="date">2011-2012</span>
                              <h2>{t('resume8_title')}</h2>
                              <span className="position">{t('resume8_institution')}</span>
                              <p className="mt-4">
                                  <a target="_blank" href="https://www.fps.hu" rel="noopener noreferrer">FPS</a> {t('resume8_description')}
                              </p>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="resume-wrap">
                              <span className="date">2009-2011</span>
                              <h2>{t('resume9_title')}</h2>
                              <span className="position">{t('resume9_institution')}</span>
                              <p className="mt-4">
                                  {t('resume9_description')}
                              </p>
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="resume-wrap">
                              <span className="date">2005-2009</span>
                              <h2>{t('resume10_title')}</h2>
                              <span className="position">{t('resume10_institution')}</span>
                              <p className="mt-4">
                                  {t('resume10_description')}
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center mt-5 mb-4 mb-sm-0 mb-xs-0 mt-sm-4 mt-xs-4">
                      <div className="col-md-6 text-center">
                          <p><a target="_blank" href={selectedLang === "no" ? "mate_szabo_cv_no.pdf" : "mate_szabo_cv.pdf"} className="btn btn-primary py-4 px-5">{t('downloadcv')}</a></p>
                      </div>
                  </div>
              </div>
          </section>

          <section className="ftco-section" id="skills-section">
              <div className="container">
                  <div className="row justify-content-center pb-5 mb-4 mb-sm-0">
                      <div className="col-md-12 heading-section text-center mb-4 mb-sm-0 mb-xs-0">
                          <h1 className="big big-2">{t('myskills')}</h1>
                          <h2 className="mb-2">{t('myskills')}</h2>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Javascript (ES6+)</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="100"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                                      <span>100%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>React (Redux, MobX)</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="100"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                                      <span>100%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Html 5</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="100"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                                      <span>100%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Css 3, Scss, Tailwind</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="100"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                                      <span>100%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Styled-components</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="100"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
                                      <span>100%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>TypeScript</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="95"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '95%' }}>
                                      <span>95%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Angular</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="90"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '90%' }}>
                                      <span>90%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Jest, Chai {t('and')} Mocha</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="90"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '90%' }}>
                                      <span>90%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Cypress.js</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="90"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '90%' }}>
                                      <span>90%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Storybook.js</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="90"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '90%' }}>
                                      <span>90%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Node.js (Express.js, Nest.js)</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="85"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '85%' }}>
                                      <span>85%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>GraphQl</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="85"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '85%' }}>
                                      <span>85%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Socket.IO</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="85"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '85%' }}>
                                      <span>85%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Vue</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="80"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '80%' }}>
                                      <span>80%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>C# {t('and')} .NET</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="80"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '80%' }}>
                                      <span>80%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>MongoDb, MySql</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="80"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '80%' }}>
                                      <span>80%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>NextJs, Webpack</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="75"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }}>
                                      <span>80%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>Grunt, Gulp, Gatsby</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="75"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }}>
                                      <span>80%</span>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 animate-box">
                          <div className="progress-wrap">
                              <h3>React Native</h3>
                              <div className="progress">
                                  <div className="progress-bar color-1" role="progressbar" aria-valuenow="70"
                                       aria-valuemin="0" aria-valuemax="100" style={{ width: '70%' }}>
                                      <span>75%</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="ftco-section contact-section ftco-no-pb" id="contact-section">
              <div className="container">
                  <div className="row justify-content-center mb-5  pb-3">
                      <div className="col-md-7 heading-section text-center">
                          <h1 className="big big-2">{t('contactme')}</h1>
                          <h2 className="mb-2">{t('contactme')}</h2>
                      </div>
                  </div>

                  <div className="row d-flex contact-info mb-5">
                      <div className="col-md-6 col-lg-3 d-flex">
                          <div className="align-self-stretch box p-4 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                                  <span className="icon-map-signs"></span>
                              </div>
                              <h3 className="mb-4">{t('address1')}</h3>
                              <p>{t('address')}</p>
                          </div>
                      </div>
                      <div className="col-md-6 col-lg-3 d-flex">
                          <div className="align-self-stretch box p-4 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                                  <span className="icon-phone2"></span>
                              </div>
                              <h3 className="mb-4">{t('phone')}</h3>
                              <p><a href="tel://36205136009">+36 20 513 6009</a></p>
                          </div>
                      </div>
                      <div className="col-md-6 col-lg-3 d-flex">
                          <div className="align-self-stretch box p-4 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                                  <span className="icon-paper-plane"></span>
                              </div>
                              <h3 className="mb-4">{t('email')}</h3>
                              <p><a href="mailto:hello@mateszabo.dev">hello@mateszabo.dev</a></p>
                          </div>
                      </div>
                      <div className="col-md-6 col-lg-3 d-flex">
                          <div className="align-self-stretch box p-4 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                                  <span className="icon-facebook"></span>
                              </div>
                              <h3 className="mb-4">Facebook</h3>
                              <p><a target="_blank" href="https://www.facebook.com/mate.szabo.prod">{t('facebook')}</a></p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>


          <section style={{ height: '3px', width: '100%', background: '#ffbd39' }} />

          <footer className="ftco-footer ftco-section">
              <div className="container">
                  <div className="row">
                      <div className="col-md">
                          <div className="ftco-footer-widget mb-4">
                              <h2 className="ftco-heading-2">{t('languages1')}</h2>
                              <ul className="list-unstyled">
                                  <li><a><span className="icon-long-arrow-right mr-2"></span>{t('english1')}</a></li>
                                  <li><a><span className="icon-long-arrow-right mr-2"></span>{t('norwegian1')}</a></li>
                                  <li><a><span className="icon-long-arrow-right mr-2"></span>{t('hungarian1')}</a></li>
                              </ul>
                              <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                                  <li>
                                      <a target="_blank" href="https://www.linkedin.com/in/-mate-szabo-/">
                                          <span className="icon-linkedin"></span>
                                      </a>
                                  </li>
                                  <li>
                                      <a target="_blank" href="https://www.facebook.com/mate.szabo.prod">
                                          <span className="icon-facebook"></span>
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-md">
                          <div className="ftco-footer-widget mb-4 footer-services">
                              <h2 className="ftco-heading-2">{t('about1')}</h2>
                              <ul className="list-unstyled">
                                  <li><a><span className="icon-long-arrow-right mr-2"></span>{t('roles1')}</a></li>
                                  <li><a><span className="icon-long-arrow-right mr-2"></span>{t('roles2')}</a></li>
                                  <li><a><span className="icon-long-arrow-right mr-2"></span>{t('roles3')}</a></li>
                                  </ul>
                          </div>
                      </div>
                      <div className="col-md">
                          <div className="ftco-footer-widget mb-4">
                              <h2 className="ftco-heading-2">{t('questions')}</h2>
                              <div className="block-23 mb-3">
                                  <ul>
                                      <li><span className="icon icon-map-marker"></span><span className="text">{t('address')}</span></li>
                                      <li style={{ marginBottom: "0" }}><a href="tel://36205136009"><span className="icon icon-phone"></span><span className="text">+36 20 513 6009</span></a></li>
                                      <li><a href="mailto:hello@mateszabo.dev"><span className="icon icon-envelope"></span><span className="text">hello@mateszabo.dev</span></a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
      </>
  );
}

export default App;
